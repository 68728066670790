import React, { Component } from "react"

import styles from "./uses.module.scss"

export class Uses extends Component {
  render() {
    return (
      <main>
        <article>
          <div>
            <div>
              <p>
                Here is a list detailing developer setups, gear, software and
                configs that I uses on a daily basis when developing software.
              </p>

              <h3 className={styles.sectionHeader}>Main hardware</h3>
              <ul>
                <li>MacBook Pro 15" 2018</li>
                <li>Iiyama G-Master G2730HSU (27") x2</li>
                <li>Apple Magic Keyboard + Magic Trackpad</li>
              </ul>

              <h3 className={styles.sectionHeader}>Desktop</h3>
              <ul>
                <li>Intel Core i7 8700K (6 cores, 3.7GHz)</li>
                <li>MSI Z370 Tomahawk</li>
                <li>Kingston Fury 16GB (DDR-4, 2400MHz) x4</li>
                <li>Gigabyte GeForce GTX 1050 Ti</li>
                <li>Samsung 960 EVO 1TB M.2-2280 NVMe SSD</li>
              </ul>

              <h3 className={styles.sectionHeader}>Desktop Apps</h3>
              <ul>
                <li>WebStorm - for JavaScript development</li>
                <li>PyCharm - for Python development</li>
                <li>
                  JetBrains Toolbox - for managing JetBrains products and
                  projects
                </li>
                <li>
                  Visual Studio Code - for smaller, "quick and dirty" projects
                  (PoCs)
                </li>
                <li>Sublime - for other text processing</li>
                <li>zsh + Oh My Zsh framework - as Terminal of choice</li>
                <li>
                  iStats Menu + iStats Menu Helpers for hardware monitoring
                  (CPU/memory/disk usage, temperatures etc.)
                </li>
                <li>Tunnelblick - for VPN connections</li>
                <li>Spectacle - for window management</li>
                <li>Greenshot - fot taking screenshots</li>
              </ul>
            </div>
          </div>
        </article>
      </main>
    )
  }
}
