import React from "react"

import Layout from "../components/layout/layout"
import { Uses } from "../components/uses/uses"

const IndexPage = () => (
  <Layout title="Uses">
    <Uses />
  </Layout>
)

export default IndexPage
